import { Component, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-slide-bienvenida',
  templateUrl: './slide-bienvenida.component.html',
  styleUrls: ['./slide-bienvenida.component.scss'],
})
export class SlideBienvenidaComponent  {
  @ViewChild('slidesRef', { static: true }) slidesRef: IonSlides;

  public options = {
    keyboard: true
  };

  constructor() {}

  public slideWillChange() {
    console.log('Slide will change');
  }

  public async slideDidChange() {
    console.log('Slide did change');

    if (!this.slidesRef) return;

      console.table({
        isBeginning: await this.slidesRef.isBeginning(),
        isEnd: await this.slidesRef.isEnd()
      });
  }

}




