import { Component } from '@angular/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';
import { on } from 'events';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
loginStatus!: boolean;

constructor(
  private authService: AuthService,
  private platform: Platform

) {
  // Observable para comprobar el estado de la sesión
  this.authService.loginStatus.subscribe(res => {
    this.loginStatus = res;
  });

  this.initializeApp();
}

async initializeApp() {
  this.platform.ready().then(async () => {
    await SplashScreen.hide({
      fadeOutDuration:200
    });
  })
}

async ngOnInit() {
  // Show the splash for two seconds and then automatically hide it:
  // await SplashScreen.show({
  //   showDuration: 1000,
  //   autoHide: true
  // });

  if(!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
    await LocalNotifications.requestPermissions();
  }
}

}
