import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Medicamento } from 'src/app/models/medicamento.model';
import { MedicamentosService } from '../../services/medicamentos.service';

@Component({
  selector: 'app-medicamento-form',
  templateUrl: './medicamento-form.component.html',
  styleUrls: ['./medicamento-form.component.scss'],
})
export class MedicamentoFormComponent implements OnInit {

  medicamento! : Medicamento;

  stateObject = {
    submitted: false,
    hasErrors: true
  };

  medicamentoForm: FormGroup;

  constructor(
    private medicamentosService: MedicamentosService,
    private router: Router) { }

  ngOnInit(): void {
    this.medicamentoForm = new FormGroup({
      nombre: new FormControl(null, [Validators.required]),
      descripcion: new FormControl(null, [Validators.required])
    });
  }

  addMedicamento() {
    this.stateObject.submitted = true;
    if (this.medicamentoForm.valid){
      this.medicamento = {
        nombre: this.medicamentoForm.get('nombre').value,
        descripcion: this.medicamentoForm.get('descripcion').value
      }

      this.medicamentosService.addMedicamento(this.medicamento);

      this.stateObject.submitted = false;
      this.router.navigateByUrl('/medicamentos', { replaceUrl: true });
    }
  }

  cancelar() {
    this.router.navigateByUrl('/medicamentos', { replaceUrl: true });
  }

}
