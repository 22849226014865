import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TituloHeaderService {

  private subject = new Subject<string>();

  sendTituloHeader(titulo: string) {
      this.subject.next(titulo);
  }

  getTituloHeader(): Observable<string> {
      return this.subject.asObservable();
  }
}
