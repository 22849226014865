import { Injectable } from '@angular/core';
import { doc, docData, Firestore, updateDoc, getDoc } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Medicamento, Medicamentos, MedicamentosDocData } from 'src/app/models/medicamento.model';
import { Perfil, PerfilesDocData } from 'src/app/models/perfiles.model';
import { PerfilesService } from './perfiles.service';
import { UsuariosService } from './usuarios.service';

@Injectable({
  providedIn: 'root'
})
export class MedicamentosService {

  constructor(
    private firestore: Firestore,
    private usuariosService: UsuariosService,
    private perfilesService: PerfilesService,
    ) {}



  /** Capturar idContador Medicamentos **/
  async getIdContadorMedicamentos(docRefId: string) {
    const medicamentosDocRef = doc(this.firestore, 'medicamentos', docRefId);
    const medicamentosDocSnap = await getDoc(medicamentosDocRef);
    const medicamentosDocSnapData = medicamentosDocSnap.data() as MedicamentosDocData;

    return medicamentosDocSnapData.idContador;
  }

  /** Listar medicamentos (Observable) **/
  getMedicamentos(docRefId: string) {
    const medicamentosDocRef = doc(this.firestore, 'medicamentos', docRefId);
    return docData(medicamentosDocRef) as Observable<MedicamentosDocData>;
  }

  /** Listar medicamento individual **/
  async getMedicamentoById(id: number) {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);

    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
    const idPerfilActual = perfilesDocSnapData.idPerfilActual;

    const medicamentosdocRef = doc(this.firestore, 'medicamentos', perfilesDocSnapData.perfiles[idPerfilActual].medicamentosDocRef);
    const medicamentosDocSnap = await getDoc(medicamentosdocRef);

    const medicamentosDocSnapData = medicamentosDocSnap.data() as MedicamentosDocData;

    if (medicamentosDocSnap.exists()) {
      console.log('getMedicamentoById:', medicamentosDocSnapData.medicamentos[id]);
      return medicamentosDocSnapData.medicamentos[id];
    }
  }

  /** Añadir nuevo medicamento **/
  async addMedicamento(medicamento: Medicamento) {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);

    if (perfilesDocSnap.exists()) {
      const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

      const idPerfilActual = perfilesDocSnapData.idPerfilActual;

      const medicamentosdocRef = doc(this.firestore, 'medicamentos', perfilesDocSnapData.perfiles[idPerfilActual].medicamentosDocRef);
      const medicamentosDocSnap = await getDoc(medicamentosdocRef);

      const medicamentosDocSnapData = medicamentosDocSnap.data() as MedicamentosDocData;

      // Generamos el indice
      const idContador = medicamentosDocSnapData.idContador + 1
      medicamento['id'] = idContador;

      // Añadimos medicamento
      let medicamentos = medicamentosDocSnapData.medicamentos as Medicamentos;
      medicamentos[idContador] = medicamento;

      console.log('Existen medicamentos, actualizando...:', medicamentos);
      await updateDoc(medicamentosdocRef, {
        idContador: idContador,
        medicamentos
      });
    }
  }

  /** Actualizar medicamento **/
  async updateMedicamento(medicamento: Medicamento) {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);

    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
    const idPerfilActual = perfilesDocSnapData.idPerfilActual;

    const medicamentosdocRef = doc(this.firestore, 'medicamentos', perfilesDocSnapData.perfiles[idPerfilActual].medicamentosDocRef);
    const medicamentosDocSnap = await getDoc(medicamentosdocRef);

    const medicamentosDocSnapData = medicamentosDocSnap.data() as MedicamentosDocData;

    // Mediante el ID asignado reemplazamos el objeto
    let medicamentos = medicamentosDocSnapData.medicamentos;
    medicamentos[medicamento.id] = medicamento;

    // Actualizamos el documento
    return updateDoc(medicamentosdocRef, {medicamentos: medicamentos});
  }

  /** Eliminar medicamento **/
  async deleteMedicamento(id: number) {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);

    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
    const idPerfilActual = perfilesDocSnapData.idPerfilActual;

    const medicamentosdocRef = doc(this.firestore, 'medicamentos', perfilesDocSnapData.perfiles[idPerfilActual].medicamentosDocRef);
    const medicamentosDocSnap = await getDoc(medicamentosdocRef);

    const medicamentosDocSnapData = medicamentosDocSnap.data() as MedicamentosDocData;

    // Mediante el ID asignado eliminamos el objeto
    let medicamentos = medicamentosDocSnapData.medicamentos;
    delete medicamentos[id];

    // Restablecemos idContador a 0, si no existen más medicamentos
    if (Object.keys(medicamentos).length === 0) {
      // Actualizamos el documento
      updateDoc(medicamentosdocRef, {idContador: 0, medicamentos: medicamentos});
    } else {
      // Actualizamos el documento
      updateDoc(medicamentosdocRef, {medicamentos: medicamentos});
    }
  }

}
