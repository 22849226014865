import { Injectable } from '@angular/core';
import { addDoc, collection, deleteDoc, doc, docData, Firestore, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { PerfilesDocData, Perfiles, Perfil } from 'src/app/models/perfiles.model';
import { UsuariosService } from './usuarios.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilesService {
  constructor(
    private firestore: Firestore,
    private usuariosService: UsuariosService
  ) {}


  async getPerfilActual() {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);
    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
    return perfilesDocSnapData.perfiles[perfilesDocSnapData.idPerfilActual];
  }

 /** Listar perfiles (Observable) **/
 getPerfiles() {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  return docData(perfilesDocRef) as Observable<PerfilesDocData>;
}

// Seleccionar perfil
async selectPerfil(id: number) {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  // Primero capturamos los datos existentes del documento
  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  const usuariosDocRef = doc(this.firestore, 'usuarios', usuarioInfo.UID);
  // const perfilesDocSnap = await getDoc(perfilesDocRef);
  // const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

  // Actualizamos los IDs en perfiles y en usuarios
  await updateDoc(perfilesDocRef, {idPerfilActual: id});
  await updateDoc(usuariosDocRef, {idPerfilActual: id});

  // Actualizamos el subject de "perfilActual" con la información del perfil seleccionado
  // this.perfilActual.next(perfilesDocSnapData.perfiles[id])
}

/** Listar perfil individual **/
async getPerfilById(id: number) {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  // "docRef" usado para guardar las referencias de los documentos de las colecciones
  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
  const perfilesDocSnap = await getDoc(perfilesDocRef);

  if (perfilesDocSnap.exists()) {
    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
    console.log('getPerfilById:', perfilesDocSnap.data()['perfiles'][id]);
    return perfilesDocSnapData.perfiles[id];
  } else {
    console.log('El documento no existe en la colección');
  }
}

/** Añadir nuevo perfil **/
async addPerfil(perfil: Perfil) {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  const perfilesDocSnap = await getDoc(perfilesDocRef);

  const usuariosDocRef = doc(this.firestore, 'usuarios', usuarioInfo.UID);

  // "Collection" usado para seleccionar colecciones y añadir documentos
  const medicamentosCollection = collection(this.firestore, 'medicamentos');
  const tomasCollection = collection(this.firestore, 'tomas');

  if (perfilesDocSnap.exists()) {
    const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

      // Generamos el indice
      const idContador = perfilesDocSnapData.idContador + 1;
      perfil['id'] = idContador;

      let perfiles = perfilesDocSnapData.perfiles as Perfiles;

      console.log('Documento perfil existe, añadiendo nuevo perfil...:');
      perfiles[idContador] = perfil;
      console.log('Perfil añadido:', perfiles);

      // Creamos los documentos con referencias asociados a un perfil
      // y los almacenamos en el perfil
      perfil['medicamentosDocRef'] = (await addDoc(medicamentosCollection, {idContador: 0, medicamentos: {}})).id;
      perfil['tomasDocRef'] = (await addDoc(tomasCollection, {idContador: 0})).id;

      if (idContador !== 1) {
        // Actualizamos el documento perfiles con el nuevo perfil, solo los campos "idContador" y "perfiles"
        await updateDoc(perfilesDocRef, {
          idContador: idContador,
          perfiles
        });
      } else {
        // Si es el primer perfil, actualizamos el documento usuarios seleccionando el nuevo perfil, solo el campo idPerfilActual
        await updateDoc(usuariosDocRef, { idPerfilActual: idContador });
        await updateDoc(perfilesDocRef, {
          idContador: idContador,
          idPerfilActual: idContador,
          perfiles
        });
      }
  }
}

/** Actualizar perfil **/
async updatePerfil(perfil: Perfil) {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  // Primero capturamos los datos existentes del documento
  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  const perfilesDocSnap = await getDoc(perfilesDocRef);
  const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

  // Preparamos el objeto para editar y acceder a los datos
  let perfiles = perfilesDocSnapData.perfiles as Perfiles;

  // Mediante el ID asignado reemplazamos el objeto
  perfiles[perfil.id] = perfil;

  // Actualizamos el documento (updateDoc actualizará únicamente el campo "perfiles")
  updateDoc(perfilesDocRef, {perfiles: perfiles});
}

/** Eliminar perfil **/
async deletePerfil(perfil: Perfil) {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
  const usuarioInfo = this.usuariosService.getUsuarioInfo();

  // Primero capturamos los datos existentes del documento
  const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
  const perfilesDocSnap = await getDoc(perfilesDocRef);
  const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

  // Preparamos el objeto para editar y acceder a los datos
  let perfiles = perfilesDocSnapData.perfiles as Perfiles;

  // Mediante el ID asignado seleccionamos el perfil a elminar
  const perfilActual = perfiles[perfil.id] as Perfil;

  // Borramos todas las referencias asociadas al perfil primero
  const medicamentosDocRef = doc(this.firestore, 'medicamentos', perfilActual.medicamentosDocRef);
  const tomasDocRef = doc(this.firestore, 'tomas', perfilActual.tomasDocRef);
  await deleteDoc(medicamentosDocRef);
  await deleteDoc(tomasDocRef);

  const usuariosDocRef = doc(this.firestore, 'usuarios', usuarioInfo.UID);

  // Si es el último perfil: Actualizamos el campo "idPerfilActual" con 0 en "perfiles" y "usuarios"
  // para indicar que no existen perfiles a la app
  if (Object.keys(perfilesDocSnapData.perfiles).length === 1) {
    // Mediante el ID asignado eliminamos el objeto del perfil a eliminar
    delete perfiles[perfil.id];
    // Actualizamos el documento perfiles y usuarios
    await updateDoc(perfilesDocRef, { idContador: 0, idPerfilActual: 0, perfiles: perfiles });
    await updateDoc(usuariosDocRef, { idPerfilActual: 0 });
    // Si no, en caso de estar borrando el perfil actual:
    // Seleccionamos el primer perfil disponible en "perfiles" y "usuarios"
  } else if (perfilesDocSnapData.idPerfilActual === perfil.id) {
    // Mediante el ID asignado eliminamos el objeto del perfil a eliminar
    delete perfiles[perfil.id];
    // Actualizamos el documento perfiles y usuarios
    await updateDoc(perfilesDocRef, { idPerfilActual: Object.keys(perfiles)[0], perfiles: perfiles });
    await updateDoc(usuariosDocRef, { idPerfilActual: Object.keys(perfiles)[0] });
  } else {
    // Mediante el ID asignado eliminamos el objeto del perfil a eliminar
    delete perfiles[perfil.id];
     // Actualizamos el documento perfiles
    await updateDoc(perfilesDocRef, {perfiles: perfiles});
  }
}

}
