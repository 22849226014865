import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TituloHeaderService } from '../../services/titulo-header.service';
import { NotificacionesService } from '../../services/notificaciones.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-header-principal',
  templateUrl: './header-principal.component.html',
  styleUrls: ['./header-principal.component.scss'],
})
export class HeaderPrincipalComponent implements OnInit {

  tituloHeader!: string;
  subscription: Subscription;

  notificacionesCount!: number;

  constructor(
    private authService: AuthService,
    private tituloHeaderService: TituloHeaderService,
    private notificacionesService: NotificacionesService,
    private platform: Platform
    ) {
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(res =>
    //   {
    //     let url = this.router.url.match(/[a-zA-Z]+.*[a-zA-Z]+/i).join().replace('-', ' ');
    //     this.paginaActual = url.charAt(0).toUpperCase() + url.slice(1);
    //   });
    // subscribe to home component messages
    this.subscription = this.tituloHeaderService.getTituloHeader().subscribe(titulo => {
      if (titulo) {
        this.tituloHeader=titulo;
      } else {
        // clear messages when empty message received
        this.tituloHeader = '';
      }
    });

  }

  async ngOnInit() {
    if(!this.platform.is('desktop') && !this.platform.is('mobileweb')) {
      await this.notificacionesService.getAlarmasCount().then((res) => {
        this.notificacionesCount = res;
      });
    }
  }

  logOut() {
    this.authService.logout();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}
