import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { PerfilesDocData } from 'src/app/models/perfiles.model';
import { UsuariosDocData } from 'src/app/models/usuarios.model';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
// idPerfilActual!: number | null;
// perfilActual!: Perfil;

  constructor(
    private firestore: Firestore,
    private auth: Auth,
    private router: Router,
  ) { }

  /**
   * Inicializa toda la información del usuario actual alojada en el documento con referencia
   * UID en la colección "usuarios"
   *
   * Esta función se llamará siempre que el usuario inicia sesión
   * y siempre que se registre un nuevo usuario
   */
    async iniUsuario() {
      // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
      const usuarioInfo = this.getUsuarioInfo();

      // "docRef" usado para guardar las referencias de los documentos de las colecciones
      const usuariosDocRef = doc(this.firestore, 'usuarios', usuarioInfo.UID);
      const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
      // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
      const usuariosDocSnap = await getDoc(usuariosDocRef);
      const perfilesDocSnap = await getDoc(perfilesDocRef);

      // Si existe el usuario continuamos:
      if (usuariosDocSnap.exists()) {
        // Si existe perfil de usuario continuamos:
        if (perfilesDocSnap.exists() ) {
          const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;
          // Si existen perfiles creados
          if (perfilesDocSnapData.idContador !== 0) {
            // -> Redirigimos a medicamentos, (o la que queramos por defecto, configurable en opciones de usuario *mejora*)
            //    allí ejecutaremos su lógica
            this.router.navigateByUrl('/medicamentos', { replaceUrl: true });
          // Si no existen perfiles creados
          } else {
            // -> Redirigimos a perfiles para que el usuario cree uno,
            //    allí ejecutaremos su lógica
            console.log('...redirigiendo a perfiles...')
            this.router.navigateByUrl('/perfiles', { replaceUrl: true });
          }
        }
      // Si no existe el usuario ni el documento perfiles:
      } else {
        // -> Log datos básicos usuario
        console.log(`El usuario no existe.
        Se va a crear el usuario:
        id:     ${usuarioInfo.UID}
        correo: ${usuarioInfo.email}
        `);
        // -> Creamos el usuario
        await setDoc(usuariosDocRef, {
          id: usuarioInfo.UID,
          idPerfilActual: 0,
          correo: usuarioInfo.email,
          perfilesDocRef: usuarioInfo.UID
        });
        // -> Log datos creados
        console.log('...¡Usuario inicialiado!')

        console.log(`El documento perfil no existe.
        Se va a crear la estructura del documento:
        perfilesDocRef: ${usuarioInfo.UID}
        idPerfilActual: null
        idContador: 0
        perfiles: {}
        `);
        // -> Creamos estructura vacía de documento perfiles
        await setDoc(perfilesDocRef, {
          perfilesDocRef: usuarioInfo.UID,
          idPerfilActual: 0,
          idContador: 0,
          perfiles: {},
        });
        // -> Log datos creados
        console.log('...¡Documento perfiles inicializado!')
        // -> Redirigimos a perfiles para que el usuario cree uno,
        //    allí ejecutaremos su lógica
        console.log('...redirigiendo a perfiles...')
        this.router.navigateByUrl('/perfiles', { replaceUrl: true });
      }
    }

  /**
   * Función auxiliar que recoge los datos del usuario de la autenticación de Firebase
   * Utilizada por la función iniUsuario()
   *
   * Podemos añadir más información contenida en "currentUser" (consultar poniendo . y ver listado)
   */
   getUsuarioInfo() {
    const usuarioInfo = {
      UID: this.auth?.currentUser?.uid,
      email: this.auth?.currentUser.email
    }
    return usuarioInfo;
  }


  /**
   * Función auxiliar inicializa listado de usuarios
   * Utilizada por la función iniUsuario()
   *
   * Podemos añadir más información contenida en "currentUser" (consultar poniendo . y ver listado)
   */
  async getUsuarioRef() {
  // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.getUsuarioInfo();

    // Primero capturamos los datos existentes del documento
    const usuariosDocRef = doc(this.firestore, 'usuarios', usuarioInfo.UID);
    const usuariosDocSnap = await getDoc(usuariosDocRef);
    const usuariosDocSnapData = usuariosDocSnap.data() as UsuariosDocData;
    return usuariosDocSnapData;
  }

}
