import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-side-menu-global',
  templateUrl: './side-menu-global.component.html',
  styleUrls: ['./side-menu-global.component.scss'],
})

export class SideMenuGlobalComponent implements OnInit {


  constructor() { }

  ngOnInit() {}

}

