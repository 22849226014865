import { Injectable } from '@angular/core';
import {
	Auth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
  onAuthStateChanged
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
loginStatus!: Observable<boolean>;

  constructor(
    private auth: Auth,
    private router: Router,
  ) {
    // Observable para el estado de la sesión
    this.loginStatus = new Observable((s) => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            console.log("loginStatus: Sesión activa");
            s.next(true)
          } else {
            console.log("loginStatus: Sesión inactiva");
            s.next(false)
          }
        })
    });
  }



  async register({ email, password }: any) {
		try {
			const user = await createUserWithEmailAndPassword(this.auth, email, password);
			return user;
		} catch (e) {
			return null;
		}
	}

	async login({ email, password}: any) {
		try {
			const user = await signInWithEmailAndPassword(this.auth, email, password);
			return user;
		} catch (e) {
			return null;
		}
	}

	async logout() {
		await signOut(this.auth);
    // { replaceUrl: true } Fuerza recargar caché del navegador de la página actual
    this.router.navigateByUrl('/login', { replaceUrl: true });

    // Borra cache local si el usuario cambia de sesión (Solo recomendable en este caso)
    // Es bueno mantener la aplicación con la caché para un buen rendimiento
    localStorage.clear();
	}

}
