import { Injectable } from '@angular/core';
import { doc, Firestore, getDoc, updateDoc } from '@angular/fire/firestore';
import { DeliveredNotifications, LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { addMinutes } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Medicamento } from 'src/app/models/medicamento.model';
import { PerfilesDocData } from 'src/app/models/perfiles.model';
import { Toma } from 'src/app/models/tomas.model';
import { MedicamentosService } from './medicamentos.service';
import { UsuariosService } from './usuarios.service';


@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {
  constructor(
    private firestore: Firestore,
    private usuariosService: UsuariosService,
    private medicamentosService: MedicamentosService,
    private platform: Platform
  ) {
    if(!this.platform.is('desktop') && !this.platform.is('mobileweb')) {

      /** Notificaciones Locales **/
      LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'ALARM_RES',
            actions: [
              {
                id: 'tomaAceptada',
                title: 'Tomada',
                destructive: true,
              },
              {
                id: 'tomaRetrasada',
                title: 'Retrasar',
              },
            ],
          },
        ],
      });

      LocalNotifications.createChannel({
        id: 'Alarmas',
        name: 'Alarmas',
        importance: 5,
        visibility: 1,
        lights: true,
        vibration: true,
        sound: "oneplusbeepalarmtone.mp3"
      })


      /** Notificaciones Push **/
      // PushNotifications.createChannel({
      //   id: 'Alarmas',
      //   name: 'Alarmas',
      //   importance: 5,
      //   visibility: 1,
      //   lights: true,
      //   vibration: true,
      //   sound: "oneplusbeepalarmtone.mp3"
      // })
    }
  }



  async getAlarmas() {
    const alarmas = await LocalNotifications.getPending();
    return alarmas;
  }


  /* Eliminar todas las notificaciones existentes */
  async removeAllListeners() {
    await LocalNotifications.removeAllListeners();
  }

  /* Descartar todas las notificaciones de la app */
  async removeAllDeliveredNotifications() {
    await LocalNotifications.removeAllDeliveredNotifications();
  }

  /* Descartar todas las notificaciones de la app especificadas*/
  async removeDeliveredNotification(notificaciones: DeliveredNotifications) {
    await LocalNotifications.removeDeliveredNotifications(notificaciones);
  }

  /* Listar todos los canales existentes */
  async listChannels() {
    const channels = await LocalNotifications.listChannels();
    return channels
  }

  async setAlarmaId() {
    const alarmas = await LocalNotifications.getPending();
    return alarmas.notifications.length ? alarmas.notifications[alarmas.notifications.length - 1].id + 1 : 1;
  }

  async getAlarmasCount() {
    const alarmas = await LocalNotifications.getPending();
    return alarmas.notifications.length ? alarmas.notifications.length : 0;
  }

  async setAlarma(fecha: Date, toma: Toma, medicamento: Medicamento) {
    const alarmaId = await this.setAlarmaId();
    let alarma = {
      title: 'Alarma toma',
      body: `${medicamento.nombre} - ${fecha.getHours()} : ${fecha.getMinutes()}`,
      id: alarmaId,
      channelId: 'Alarmas',
      actionTypeId: 'ALARM_RES',
      ongoing: true,
      autoCancel: false,
      schedule: {
        on: {
          year: fecha.getFullYear(),
          month: fecha.getMonth(),
          day: fecha.getDate(),
          hour: fecha.getHours(),
          minute: fecha.getMinutes(),
        },
      },
    }


    // console.log('Notificacion', `
    // ${fecha.getFullYear()}
    // ${fecha.getMonth()}
    // ${fecha.getDate()}
    // ${fecha.getHours()}
    // ${fecha.getMinutes()}`);

    await LocalNotifications.schedule({notifications: [alarma]});
    // this.addNotificacion(alarma);
    await LocalNotifications.addListener(
      'localNotificationActionPerformed',
      async (res) => {
        if (res.actionId === 'tomaRetrasada') {
          const DATE = utcToZonedTime(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone);
          alarma.schedule.on.minute = addMinutes(DATE, 1).getMinutes();
          await LocalNotifications.schedule({notifications: [alarma]});
          console.log('GKLABS-NOTIFICATION: retrasar alarma :D!');
        } else {
          medicamento.unidades -=  toma.dosis;
          this.medicamentosService.updateMedicamento(medicamento);
          console.log('GKLABS-NOTIFICATION: Descontar stock!\n', medicamento);
        }
      }
    );
  }

  /** Añadir nueva notificacion **/
  async addNotificacion(notificacion: any) {
    // UID usuario actual (Identificador único ubicado en Autenticación de Firebase)
    const usuarioInfo = this.usuariosService.getUsuarioInfo();

    // "docRef" usado para guardar las referencias de los documentos de las colecciones
    const perfilesDocRef = doc(this.firestore, 'perfiles', usuarioInfo.UID);
    // "DocSnap" usado para obtener un documento como objeto y consultar datos de un documento
    const perfilesDocSnap = await getDoc(perfilesDocRef);

    if (perfilesDocSnap.exists()) {
      const perfilesDocSnapData = perfilesDocSnap.data() as PerfilesDocData;

      const idPerfilActual = perfilesDocSnapData.idPerfilActual;

      const notificacionesDocRef = doc(this.firestore, 'notificaciones', perfilesDocSnapData.perfiles[idPerfilActual].medicamentosDocRef);
      const notificacionesDocSnap = await getDoc(notificacionesDocRef);

      const notificacionesDocSnapData = notificacionesDocSnap.data() as any;

      // Generamos el indice
      const idContador = notificacionesDocSnapData.idContador + 1
      notificacion['id'] = idContador;

      // Añadimos medicamento
      let notificaciones = notificacionesDocSnapData.notificaciones as any;
      notificaciones[idContador] = notificacion;

      console.log('Existen notificaciones, actualizando...:', notificaciones);
      await updateDoc(notificacionesDocRef, {
        idContador: idContador,
        notificaciones
      });
    }
  }

  iniPushNotifications() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        // alert('Push registration success, token: ' + token.value);
        console.log('GKLABS-TOKEN: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
        console.log('GKLABS-ERROR: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        console.log('GKLABS-PUSH: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        console.log('GKLABS-PUSH-ACTION: ' + JSON.stringify(notification));
      }
    );
  }

}
