import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicamentoFormComponent } from './components/medicamento-form/medicamento-form.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideMenuGlobalComponent } from './components/side-menu-global/side-menu-global.component';
import { SlideBienvenidaComponent } from './components/slide-bienvenida/slide-bienvenida.component';
import { HeaderPrincipalComponent } from './components/header-principal/header-principal.component';
import { RouterLink } from '@angular/router';


@NgModule({
  declarations: [
    MedicamentoFormComponent,
    SideMenuGlobalComponent,
    SlideBienvenidaComponent,
    HeaderPrincipalComponent
  ],
  imports: [
    CommonModule,
    IonicModule ,
    FormsModule,
    ReactiveFormsModule,
    RouterLink
  ],
  exports: [
    MedicamentoFormComponent,
    SideMenuGlobalComponent,
    SlideBienvenidaComponent,
    HeaderPrincipalComponent,
    IonicModule,
    FormsModule,
    ReactiveFormsModule 
  ]
})
export class SharedModule { }
